import React from "react";
//import { Link } from "react-router-dom";

// components

//import Navbar from "components/Navbars/AuthNavbar.js";
//import Footer from "components/Footers/Footer.js";

export default function PolicyMain() {
  return (
    <>
{/*       <Navbar transparent /> */}
      <main>
{/*         <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
           <div
            className="absolute top-0 w-full h-full bg-center bg-cover bg-red-800"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')",
            }}
          > 
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span> 
          </div>*/}
{/*           <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-5xl">
                    Your story starts with us.
                  </h1>
                  <p className="mt-4 text-lg text-blueGray-200">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nec massa at risus sodales vulputate a at neque. Sed nunc magna, vehicula in magna vitae, feugiat vestibulum mauris. Vestibulum vel tincidunt libero. Donec luctus euismod mi, sit amet auctor nunc aliquet eu. Vivamus vitae dapibus nibh. Suspendisse quis orci urna. Aliquam accumsan ipsum vitae elementum vulputate. Vestibulum faucibus odio ac turpis tristique mollis. In sit amet mauris eu felis bibendum cursus a a diam.
                  </p>
                </div>
              </div>
            </div>
         </div> */} 
{/*           <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div> 
        </div>*/}

 {/*        <section className="pb-20 bg-blueGray-200 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Product/Service 1</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                    Product/Service 1Product/Service 1Product/Service 1Product/Service 1Product/Service 1Product/Service 1.
                    </p>
                  </div>
                </div>
              </div> */}

{/*               <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Credit for Yarn purchase</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                    Status Capitals offers a credit to purchase yarn. 
                    </p>
                  </div>
                </div>
              </div> */}

{/*               <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Product/Service 2</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                    Product/Service 2Product/Service 2Product/Service 2Product/Service 2Product/Service 2Product/Service 2.
                    </p>
                  </div>
                </div>
              </div> 
            </div>*/}

{/*             <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Working with us is a pleasure
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600" >
                  D&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;t.
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                  The kit comes with three pre-built pages to help you get
                  started faster. You can change the text and images and you're
                  good to go. Just make sure you enable them first via
                  JavaScript.
                </p> 
                  <Link to="/" className="font-bold text-blueGray-700 mt-8">
                  Check Notus React!
                </Link> 
              </div>*/}

{/*               <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block h-95-px -top-94-px"
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-lightBlue-500 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                      Top Notch Services
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
                      The Arctic Ocean freezes every winter and much of the
                      sea-ice then thaws every summer, and that process will
                      continue whatever happens.
                    </p>
                  </blockquote>
                </div>
              </div> 
            </div>
          </div>
        </section>*/}

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-bottom flex flex-wrap">
              {/* <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                />
              </div> */}
              <div className="w-full md:w-12/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  {/* <div className="text-lightBlue-700 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-lightBlue-400">
                    <i className="fas fa-rocket text-xl"></i>
                  </div> */}
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                  <b>1. Introduction</b><br></br>
This Privacy Policy ("Policy") of Status Capitals Pvt Ltd has been created to comply with the legal requirement under the provisions of the
Information Technology Act, 2000 and RBI Guidelines including Digital Lending dated Sep 02, 2022 and amendments thereto that require intermediaries to
publish the rules and regulations, privacy policy and terms for access or usage of their website/mobile app and is primarily governed by Rule 3 (1) of the
Information Technology (Intermediaries Guidelines) Rules, 2011 and the Information Technology (Reasonable Security Practices and Procedures and
Sensitive Personal Data or Information) Rules, 2011 or other agencies and other applicable regulations. This Policy is applicable to Status Capitals Pvt Ltd
and its subsidiaries/associates. 
<br></br><br></br>
Status Capitals Pvt Ltd collects, uses, discloses and transfers personal information of users through its websites, 
mobile applications, digital lending applications, or, and online & offline services (herein collectively referred to as “Company”, "App", “We”, ”website”, “Us or “Our”). This policy
applies to all the existing and prospective customers who visit us or with whom we interact, including visitors to our website, service providers/receivers or
other suppliers and others who interact with whether via our website or by corresponding with us or by other means (hereinafter collectively referred to
as "Users"). For the purposes of the Privacy Policy, "You" or "Your" shall mean the person who is accessing the website or app including natural or legal person
who accesses or uses the app or access in person and in the event that a natural person is representing a business entity or a body corporate, reference to such
terms shall include a reference to such business entity and/or body corporate as well.
<br></br><br></br>
We collect information about you and/or your usage to provide better services and offerings. The Personal Information that we collect, and how we collect it,
depends upon how you interact with us. 
<br></br><br></br>

By using our services, you expressly and unconditionally agree to the terms and conditions of this Privacy Policy and consent to the usage, storage and handling
of the personal information/ sensitive personal data submitted by You in accordance with the terms contained herein and as per regulatory requirement.
<br></br><br></br>

This policy applies only to the information We collect through Our website or app or in physical mode, in email, text and other electronic communications sent
through or in connection therewith (collectively "User Information"). When You submit User Information on our application, it shall be deemed that You have
granted Us the right to collect, store, process, handle and use such User Information, in accordance with this Privacy Policy (as amended from time to
time).

<br></br><br></br>
We intend to protect the privacy of its users and members. The privacy of our website users, whether you are our former or existing registered user or merely
a visitor to our Website, is important to us and we are strongly committed to your right to privacy and to keeping your personal and other information
secure. We encourage you to read our privacy statement to understand what types of personally identifiable information we collect and how we use this
information. 
<br></br><br></br>

If you have any queries about this Privacy Policy, you can reach out to us at support@statuscapitals.com
<br></br><br></br>

<b>2. What we collect</b><br></br>

We collect various types of information from and about Users of Our services,
including:
<br></br><br></br>

- If you choose to become a registered member of our Website, you are required
to share your name, e-mail address, gender, date of birth, postal address, phone
number, a unique login name, password, password validation, income tax
details, GST Details, marital status, family details, business information and other details 
shared via application form or via email or via any other medium (Personally
Identifiable Information).

<br></br><br></br>
- We may also collect other information specific to provide you service each time
such as name, bank statements, KYC documents, personal details, business
details, income tax details, marital status, family details and other information
via application form or via email or via any other electronic medium or via
printed form.

<br></br><br></br>
- When you visit the Website/App, we use cookies, web beacons and other tracking
technologies to automatically collect, store and use technical information about
your system and interaction with our Website/App.

<br></br><br></br>
-If you choose to register with us using your GST account to access the Website/App, you
consent to our collection, storage, and use, in accordance with this Privacy
Policy, of the information that you make available to us through GST account.
<br></br><br></br>
<b>3. How we collect</b><br></br>

We typically collect personal/business data about from the information provided to us or
others acting on our behalf when communicating or transacting with us in at the time of registering on the Website/App 
or communicating with us. We may also automatically collect technical data about your business, KYC. In addition, your personal/business data may be received
from third parties, such as public sources or information vendors with explicit prior consent of customer

<br></br><br></br>
Collection of data by Our website/app is need-based and with prior and explicit consent of the
borrower having audit trail. Digital website/app cannot access mobile phone resources like file
and media, contact list, call logs, telephony functions, etc. A one-time access can be
taken for camera, microphone, location or any other facility necessary for the purpose
of on-boarding/ KYC requirements only, with the explicit consent of the borrower.
No biometric data is stored/ collected in the systems associated with us or website/app,
unless allowed under extant statutory guidelines and data is stored only in servers
located within India, while ensuring compliance with statutory obligations/ regulatory
instructions.

<br></br><br></br>
<b>4. How we use the collected information
</b><br></br>

We will only use your personal/business data in a fair and reasonable manner, and where
we have a lawful reason to do so. The Personal Information collected may be
used for the following purposes:
<br></br><br></br>
− To communicate and correspond with you to serve you better;
<br></br><br></br>
− To maintain our records;
<br></br><br></br>
−Providing our services and products to you including to send you notifications of purchase, payments;
<br></br><br></br>
−Improving the Website/App and its content to provide better features and services;
<br></br><br></br>
− Conducting market research and surveys with the aim of improving our
products and services;
<br></br><br></br>
−Preventing, detecting, investigating and taking action against crimes
(including but not limited to fraud and other financial crimes), any other
illegal activities, suspected fraud, or violations of our ‘Terms of Use’ in any
jurisdiction;
<br></br><br></br>
−Corresponding with third parties such as service providers, legal advisors
and regulatory authorities to comply with any legal obligation imposed
on us or in order to pursue our legitimate business interests.

<br></br><br></br>
In addition to the uses above, please note that we may also process your
information where we are required by law to do so or if we reasonably believe
that it is necessary to protect our rights and/or to comply with judicial or
regulatory proceedings, a court order or other legal process.

<br></br><br></br>
<b>5. Information Sharing 
</b><br></br>

Your data may be shared to following with prior explicit consent :
<br></br><br></br>

−Our affiliates and group companies to the extent required for our internal
business and/or administrative purposes and/or general corporate
operations and for provision of services;

<br></br><br></br>
−Professional advisers including lawyers, bankers, auditors and insurers
to the extent such information is relevant to their performance of their
services and any of our service providers where such information is
relevant to their performance of such services;

<br></br><br></br>
−Third parties including enforcement, regulatory and judicial authorities,
if we determine that disclosure of your Personal Information is required
to a) respond to subpoenas, court orders, or legal process, or to establish
or exercise our legal rights or defend against legal claims; or b)
investigate, prevent, or take action regarding illegal activities, suspected
fraud, situations involving potential threats to the physical safety of any
person, violations of our Terms of Use or as otherwise required by law;

<br></br><br></br>
−In the event of a merger, acquisition, financing, or sale of assets or any
other situation involving the transfer of some or all of our business assets
we may disclose Personal Information to those involved in the
negotiation or transfer.

<br></br><br></br>
−We may also need to share your personal data with a regulator or to
otherwise comply with applicable law or judicial process or if we
reasonably believe that disclosure is necessary to protect our rights
and/or to comply with judicial or regulatory proceedings, a court order
or other legal process

<br></br><br></br>
The collection and use of personal/business data should be limited to purposes: (1) which
are stated in law and thus can be known to the individual at the time of the data
collection; or (2) for which the individual has given consent.

<br></br><br></br>
Customer can revoke the consent and delete the data at any time given on the
digital application.

<br></br><br></br>
<b>6. Retention of Personal Information 
</b><br></br>

Your Personal/Business Information will not be retained by us any longer than, necessary
for the purposes for which the Personal/Business Information is processed and/or in
accordance with legal, regulatory, contractual or statutory obligations as
applicable.
<br></br><br></br>
To the extent of instructions on record management as stipulated in the Master
Directions/Directions/Guidelines/Guidance issued by Reserve Bank of India,
with reference to provisions of Prevention of Money Laundering (Maintenance
of Records) Rule, 2005 (PML Rule, 2005) from time to time would be the
directing force, We have defined a record retention period for your Information
as Six (06) years from the date of last processing of your Information. At the
expiry of such periods, your Personal/Business Information will be deleted or archived in
compliance with applicable laws. All physical data will be disposed of by
shredding or otherwise making unreadable confidential records in accordance
with applicable laws. All electronic data will be disposed in a responsible
manner and in compliance with applicable laws with prior approval from
CTO/CISO.

<br></br><br></br>
<b>7. Accuracy of information provided
</b><br></br>

To serve you better we need accurate data. Please let us know if your personal/business
data which we hold changes during your relationship with us. If at any time you
would like to rectify, update or correct your Personal Information; or obtain
confirmation on whether or not your Personal Information is processed by it;
or access your Personal Information or exercise your right to data portability;
or exercise your right to restrict the continuing disclosure of your Personal
Information to any third party by us in certain circumstances, you are requested
to contact us. We will require you to provide a valid proof of your identity, in
order to ensure that your rights are respected.

<br></br><br></br>
<b>8. Control over your personal information
</b><br></br>

You have the right to deny consent for use of specific data, restrict disclosure to
third parties, data retention, revoke consent already granted to collect personal
data and if required, make the app delete/ forget the data.
To act upon above, you can contact us at support@statuscapitals.com. Once we have
received the withdrawal request, we will no longer process your information
unless it is required to hold your data for a legal or regulatory purpose.

<br></br><br></br>
<b>9. Promotional Initiatives
</b><br></br>

We may have presence on social networking websites including but not limited
to LinkedIn, Facebook, Twitter, YouTube and blogs which are promotional and
business initiatives to connect to a larger group of people. The domain links
contained therein may either direct you to our Website or request your
participation by way of feedback, suggestions. The Company in this regard fully
disclaims any liability (ies) or claim(s), which may arise by use/misuse of your
feedback, suggestions, views, etc. on any of the aforementioned networking
websites or blogs, by any third party whether or not known to Us.

<br></br><br></br>
<b>10. Third Party Content
</b><br></br>
Please be aware that the Website/App sometimes contains links to other sites that are
not governed by this Privacy Policy. Users may be directed to third-party sites
for more information, such as advertisers, blogs, content sponsorships, vendor
services, social networks, etc.
<br></br><br></br>We make no representations or warranties regarding how your information is
stored or used on third-party servers. We recommend that you review the
applicable privacy statements and policies of each third-party site linked from
the Website/App to determine their use of your personal information.
<br></br><br></br>
<b>11. Intellectual Property Rights
</b><br></br>

All content on Our Website/App, including graphics, text, icons, interfaces, audio clips,
logos, images, reviews, comments, and software is the property of the Company
and/or its content suppliers and is protected by Indian and international
copyright laws and other applicable intellectual property laws. Any use,
including the reproduction, modification, distribution, transmission, republication, display, or performance, of the content on this Website/App can only be
made with the express written permission of the Company.
<br></br><br></br>
<b>12. Security of Data
</b><br></br>
We use a combination of firewall barriers, encryption techniques and
authentication procedures, among others, to maintain the security of the User’s
online session and to protect accounts and systems from unauthorized access.
Our databases are protected from general employee access both physically and
logically. However, no method of transmission over the internet or via mobile
device, or method of electronic storage, is absolutely secure. Therefore, while
We endeavour to use commercially acceptable means to protect Your personal/business
information, We cannot guarantee its absolute security. You agree and confirm
that Your User/Business Information may be transferred, processed and stored. You
hereby confirm that You have been made aware of the security measures
undertaken by Us and You expressly consent to Us storing, handling, using Your
User/Business Information.
<br></br><br></br>
The processing of personal/business data in accordance with the above principles should
be monitored by an appropriate, independent oversight authority, and by data
subjects themselves. If there is any breach of security then we shall indemnify
the user/ customer on actual loss basis.
<br></br><br></br>
<b>13. Lawfulness
</b><br></br>
The collection and use of personal/business data should be done on a lawful basis in
compliance with Information Technology (Reasonable Security Practices and
Procedures and Sensitive Personal Data or Information), Rules, 2011 ("Rules"),
Information Technology Act, 2000, technology standards/cybersecurity stipulated by RBI or other agencies and other applicable regulations., e.g.,
involving consent, contractual necessity, compliance with legal obligation,
protection of vital interests, public interest and/or legitimate interest.

<br></br><br></br>
<b>14. Changes to Privacy Policy
</b><br></br>

We reserve the right to update this Privacy Notice at any time, and we will make
an updated copy of such Privacy Notice available to you and notify you when we
make any substantial updates. This Privacy Notice was written with brevity and
clarity in mind and is not an exhaustive account of all aspects of our collection
and use of personal data. If you require any further information, please do not
hesitate to contact support@statuscapitals.com.
<br></br><br></br>
<b>15. Governing Law and Dispute Resolution
</b><br></br>

This Policy shall be governed by and construed in accordance with the laws of
India and subject to the provisions of arbitration set out herein, the courts at
Delhi shall have exclusive jurisdiction in relation to any disputes arising out of
or in connection with this Policy. If any dispute arises between Us and the User
in connection with or arising out of the validity, interpretation, implementation
or alleged breach of any provision of the Policy, such dispute shall be referred
to and finally resolved by arbitration in accordance with the Indian Arbitration
and Conciliation Act, 1996 for the time being in force, which rules are deemed
to be incorporated by reference in this clause.

<br></br><br></br>
<b>16. Complaint/Concerns/Grievance
</b><br></br>
In accordance with the relevant provisions of the Information Technology Act,
2000 and Rules and RBI Guidelines on Digital Lending dated Sep 02, 2022, made
thereunder, the name and contact details of the Grievance Officer who can be
contacted with respect to any complaints or concerns including those
pertaining to breach of Our Terms of Use, Privacy Policy and other polices or
questions are published as under:
<br></br><br></br>
Grievance Redressal Officer: Mr. Shreyas Kitttur
<br></br><br></br>
Email ID: shreyas.kittur@yarnbiz.com
<br></br><br></br>
Contact Number: +91-8104989543
<br></br><br></br>
The Grievance Officer can be contacted between 10:30 a.m. to 6:00 p.m. from
Monday to Friday except on public holidays.
<br></br><br></br>
<b>17. Review
</b><br></br>
This policy maybe be amended periodically as and when statutorily required.







 </p>

                  {/* <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-fingerprint"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Carefully crafted components
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Amazing products
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="far fa-paper-plane"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Dynamic services
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </section>


{/*         <section className="pb-20 relative block bg-blueGray-800">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-800 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Build something
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                  Put the potentially record low maximum sea ice extent tihs
                  year down to low ice. According to the National Oceanic and
                  Atmospheric Administration, Ted, Scambos.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-medal text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Excelent Services
                </h6>
                <p className="mt-2 mb-4 text-blueGray-400">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-poll text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Grow your market
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-lightbulb text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Launch time
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
        </section> 
        <section className="relative block py-24 lg:pt-0 bg-blueGray-800">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                  <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl font-semibold">
                      Want to work with us?
                    </h4>
                    <p className="leading-relaxed mt-1 mb-4 text-blueGray-500">
                      Complete this form and we will get back to you in 24
                      hours.
                    </p>
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Full Name"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        Message
                      </label>
                      <textarea
                        rows="4"
                        cols="80"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Type a message..."
                      />
                    </div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>*/}
      </main>
{/*       <Footer /> */}
    </>
  );
}
