import React from "react";
//import { Link } from "react-router-dom";

// components

//import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import TermsHero from "./TermsHero";
import TermsMain from "./TermsMain";

export default function Terms() {
  return (
    <>
{/*       <Navbar transparent /> */}
      <IndexNavbar fixed />
      <TermsHero/>
      <TermsMain/>  
      <Footer />
    </>
  );
}
