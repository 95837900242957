import React, {useState} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IndexNavbar from "components/Navbars/IndexNavbar.js";


const Nach = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [apibaseurl, setapibaseurl]=useState('https://los.statuscapitals.com/apigateway/statusapi/yarnbiz/upload/enachform/');
  const [apiactivity, setapiactivity]=useState('https://los.statuscapitals.com/apigateway/statusapi/activitydashboard/activitydetails?buyerId=');
  const [iLeadNo, setiLeadNo] = useState();
  const [isLoad, setisLoad] = useState(true);
  const [orgname,setorgname] = useState('');
  const [open, setOpen] = useState(false);
  const [NACHError, setNACHError] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');
  const [urlerror, seturlerror] = useState(false);
  
  let encoded = (window.location.href.substring(window.location.href.lastIndexOf('/') + 1))
  let locationpath = (window.location.protocol + "//" + window.location.host + "/");
  let mylan = Buffer.from(encoded,'base64').toString();

  if (!Number.isInteger(parseInt(mylan, 10))) {
    if (isLoad) {
      setNACHError(true);
      seterrorMessage('Something went wrong or wrong url, please try after sometime or with correct url');
      seturlerror(true);
    }
  } 
  if (isLoad && (!urlerror) ) {
      setisLoad(false);
      let url = apiactivity + mylan;
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: url,

          headers: { 
            'client-id': 'STATU0004200016', 
            'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
            'Accept': 'application/json', 
            'Content-Type': 'application/json'
          }
        };
        axios.request(config)
        .then((response) => {
          console.log(response.data);
          if (JSON.stringify(response.data.responseCode)==200){
            setiLeadNo(response.data.data[0].leadNo);
            setorgname(response.data.data[6].orgName);
          }
        })
        .catch((error) => {
          setNACHError(true);
          seterrorMessage('Something went wrong or wrong url, please try after sometime or with correct url');
          seturlerror(true);
        });

  }


  // Handle file selection
  const handleFileChange = (event) => {
    console.log(event);
    setSelectedFile(event.target.files[0]);
  };

  const card = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5" component="div">
          {orgname}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        </Typography>
        <Typography variant="body2">
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        NACH form upload
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </React.Fragment>
  );

  const handleClose = () => {
    setOpen(false);
    setNACHError(false);
    seterrorMessage('');
  
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      // setOpen(true);

      try {
        const myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        //myHeaders.append("Content-Type", "multipart/form-data");
        myHeaders.append("client-id", "STATU0004200016");
        myHeaders.append("client-secret", "QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=");
        myHeaders.append("Accept", "application/json");
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJyaXRlc2gucGFuZGV5QDRmaW4uaW4iLCJleHAiOjE2OTA1MjA3NDUsImlhdCI6MTY4NzkyODc0NX0.mwiWCvDoMdwrjH9cIqXQpxdNNSQWT9ZkhfHAehVHP5M");
        
        const formdata = new FormData();
        //formdata.append("file", fileInput.files[0], "/C:/Users/YarnBiz Tech/Downloads/NACH.pdf");
        formdata.append('file',selectedFile);
        console.log(formdata);
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow"
        };
        let url = apibaseurl + iLeadNo;
        fetch(url, requestOptions)
          .then((response) => {
            //console.log(response.status);
          })
          .then((result) => {setOpen(true);
            //console.log("success2")
          })
          .catch((error) => {
            setNACHError(true);
            seterrorMessage('Sorry! Issue in NACH Form Uploaded, please try with other file or after sometime'); 
          });
      } catch (error) {
        //console.error('Error uploading file:', error);
        setNACHError(true);
        seterrorMessage('Sorry! Issue in NACH Form Uploaded, please try with other file or after sometime');      }
    }
  };

  return (

    <div> 
    {/* <IndexNavbar/> */}
      <Snackbar open={open} autoHideDuration={6000} >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
          >
          Congratulations! NACH Form successfully uploaded to the system, you can close this windows now!
        </Alert>
      </Snackbar>
      <Snackbar open={NACHError} autoHideDuration={6000}>
        <Alert
          onClose={handleClose}
          severity='error'
          variant="filled"
          sx={{ width: '100%' }}
          >
          {errorMessage}  
          {/* Sorry! Issue in NACH Form Uploaded, please try with other file or after sometime */}
        </Alert>
      </Snackbar>

      <Card variant="outlined">{card}
      <form onSubmit={handleSubmit}>
      <TextField
            //label="NACH Form upload"
          placeholder='Upload NACH Form'
          variant="outlined"
          //value={directorDOB}
          type='file'
          required
          onChange={(e) => handleFileChange(e)}
          size='small'
          disabled={urlerror}    
          // inputProps={{
          // maxLength:10,
          // min: (moment(new Date()).subtract(100,"year").format('yyyy-MM-DD')),
          // max: (moment(new Date()).format('YYYY-MM-DD'))
          // }}        
          //error={!validDOB}
          helperText={'Selecte NACH filled form file to Upload'}

        />
        &nbsp;&nbsp;
        {/* <input type="file" onChange={handleFileChange} /> */}
        {/* <button type="submit">Upload</button> */}
        <Button variant="contained" type='submit' color='success' disabled={urlerror}>Upload selected file</Button>
      </form>
</Card>
    </div>
  );
};

export default Nach;