
import createBrowserHistory from 'history/createBrowserHistory';
const checksession = function() {

    let registeruser = sessionStorage.getItem("registeruser");
    let returnvalue = false;
    const history = createBrowserHistory({forceRefresh:true});

    if (registeruser ==="YES") { 
        returnvalue = true;

    }else{
        history.push('/');
    }
    //alert(registeruser);
    //alert(usermobile);
    //alert(sessionstart);

    /* ] */
    
    return returnvalue;
  }

  export { checksession };