import React, { useState, forwardRef} from 'react';
/* import './App.css'; */
import MaterialTable, { MTableToolbar } from 'material-table';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import { Button } from "@material-ui/core";
//Material UI
import { Paper } from "@material-ui/core";

//icons
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterAltIcon {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    CurrencyRupeeIcon: forwardRef((props, ref) => <CurrencyRupeeIcon {...props} ref={ref} />)
    
  };

const invList = [
/*   { id: 1, name: "Neeraj", email: 'neeraj@gmail.com', phone: 9876543210, city: "Bangalore" },
  { id: 2, name: "Raj", email: 'raj@gmail.com', phone: 9812345678, city: "Chennai" },
  { id: 3, name: "David", email: 'david342@gmail.com', phone: 7896536289, city: "Jaipur" },
  { id: 4, name: "Vikas", email: 'vikas75@gmail.com', phone: 9087654321, city: "Hyderabad" },
 */
  {
    "selecpay": false,  
    "invoiceNo": 6104501,
    "invoiceDate": "01-07-2023",
    "invoiceAmount": 1001,
    "creditDays": 7,
    "dueDate": "08-07-2023",
    "dueAmount": 111011,
    "invStatus" :"Pending",
    },  
    {
      "selecpay": false,  
      "invoiceNo": 6104502,
    "invoiceDate": "02-07-2023",
    "invoiceAmount": 1011,
    "creditDays": 15,
    "dueDate": "19-07-2023",
    "dueAmount": 1031,
    "invStatus" :"Pending",
    },
    {
      "selecpay": false,  
      "invoiceNo": 6104503,
    "invoiceDate": "03-07-2023",
    "invoiceAmount": 1021,
    "creditDays": 30,
    "dueDate": "03-08-2023",
    "dueAmount": 1051,
    "invStatus" :"Pending",
    },
    {
      "selecpay": false,  
      "invoiceNo": 6104504,
    "invoiceDate": "04-07-2023",
    "invoiceAmount": 1031,
    "creditDays": 7,
    "dueDate": "11-07-2023",
    "dueAmount": 1041,
    "invStatus" :"NACH Presented",
    },
    {
      "selecpay": false,  
      "invoiceNo": 6104506,
    "invoiceDate": "05-07-2023",
    "invoiceAmount": 1051,
    "creditDays": 15,
    "dueDate": "21-07-2023",
    "dueAmount": 1071,
    "invStatus" :"Pending",
    },
    {
      "selecpay": false,  
      "invoiceNo": 6104507,
    "invoiceDate": "06-07-2023",
    "invoiceAmount": 1071,
    "creditDays": 30,
    "dueDate": "06-08-2023",
    "dueAmount": 1111,
    "invStatus" :"NACH Presented",
    },

]
let paymentvalue = 0;
let invdueamt = 0
var invoicelist = [];
var invoiceindex;
//payment = 109;


export default function Payments() {
    const defaultMaterialTheme = createTheme();
    const [data, setData] = useState(invList);
    const initialText = 'Select the invoices for payment';
    const [buttonText, setButtonText] = useState(initialText);
    const columns = [
/*         { title: "ID", field: "id", editable: false },
        { title: "Name", field: "name" },
        { title: "Email", field: "email" },
        { title: "Phone Number", field: 'phone', },
        { title: "City", field: "city", } */
        { title: 'Payment?', field:'selecpay',type:'boolean'
        //, render: rowData => (rowData.invStatus === 'NACH Presented' ?  <input type="checkbox" disabled/> : <input type="checkbox" checked/>),
          //render: rowData => { return true
            /* if (rowData.invStatus === 'NACH Presented' || rowData.dueAmount < 1 ){
              return false
            } else {
              return true
            }   */
            
         // }
        },
        
        { title: 'To be Pay', field:'tobepay', type:'currency',editable:"onUpdate", currencySetting:{ currencyCode:'INR', minimumFractionDigits:0, maximumFractionDigits:0}, 
        },     
        { title: 'Invoice No', field:'invoiceNo',editable:"never" },
        { title: 'Invoice Date', field:'invoiceDate',editable:"never"  },
        { title: 'Invoice Amount', field:'invoiceAmount', type:'currency', currencySetting:{ currencyCode:'INR', minimumFractionDigits:0, maximumFractionDigits:0},editable:"never"},
        { title: 'Credit Days', field:'creditDays', type:'numeric',editable:"never"},
        { title: 'Due Date', field:'dueDate',editable:"never"},
        { title: 'Due Amount', field:'dueAmount', type:'currency', currencySetting:{ currencyCode:'INR', minimumFractionDigits:0, maximumFractionDigits:0},editable:"never"},
        { title: 'Invoice Status', field:'invStatus', editable:"never"},
    ]


    const handleCheckboxClick = (clickstatus, payamount, invoiceno, dueamt) => {
      var buttext = initialText;
      invoiceindex = invoicelist.indexOf(invoiceno);
      if (clickstatus) {
          paymentvalue = paymentvalue + payamount;
          invdueamt = invdueamt + dueamt;
          invoicelist.push(invoiceno);
      }else{
          paymentvalue = paymentvalue - payamount; 
          invdueamt = invdueamt - dueamt;
          invoiceindex = invoicelist.indexOf(invoiceno);
          invoicelist.splice(invoiceindex,1);
      }
      if (paymentvalue > 0 ){
        buttext = "Pay " + (new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR',maximumFractionDigits: 0,minimumFractionDigits: 0,}, ).format(paymentvalue))  + " for "+ invoicelist.length +" invoice(s) against due amt : " + (new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR',maximumFractionDigits: 0,minimumFractionDigits: 0,}).format(invdueamt)) ;
      } 
      setButtonText(buttext);
  }



  return (
    <div >
        <main>
            <IndexNavbar fixed />
            <section className="relative py-20">
                <ThemeProvider theme={defaultMaterialTheme}>
                  
                    <MaterialTable 
                        title=""
                        icons={tableIcons}
                        data={data}
                        columns={columns}

                        components={{
                            Toolbar: (props) => (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center"
                                }}
                              >
                                <Button
                                  style={{ height: "fit-content" }}
                                  color="primary"
                                  variant="contained"
                                >
                                  {buttonText}
                                </Button>
                                <div style={{ width: "13rem" }}>
                                  <MTableToolbar {...props} />
                                </div>
                              </div>
                            ),
                            Container: (props) => <Paper {...props} elevation={8} />
                          }}


                        editable={{
                            isEditHidden: rowData => rowData.invStatus === 'NACH Presented',
                            //isDeletable: rowData => rowData.name === 'b', // only name(b) rows would be deletable,
                            isDeleteHidden: rowData => 1=== 1, /* => ({
                                disabled: rowData.invStatus != 'NACH Presented',
                                color: 'primary'}), */
                            /*                             onRowAdd: (newRow) => new Promise((resolve, reject) => {
                              const updatedRows = [...data, { id: Math.floor(Math.random() * 100), ...newRow }]
                              setTimeout(() => {
                                setData(updatedRows)
                                resolve()
                              }, 2000)
                            }), */
                            onRowAdd:undefined,
                            onRowDelete:undefined,
/*                             onRowDelete: selectedRow => new Promise((resolve, reject) => {
                              const index = selectedRow.tableData.id;
                              const updatedRows = [...data]
                              updatedRows.splice(index, 1)
                              setTimeout(() => {
                                setData(updatedRows)
                                resolve()
                              }, 2000)
                            }), */
                            
                            onRowUpdate:(updatedRow,oldRow)=>new Promise((resolve,reject)=>{
                              if( updatedRow.tobepay > updatedRow.dueAmount){
                                alert("You can not be pay more than Due Amount: " + (new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR',}).format(updatedRow.dueAmount)) + " for this invoice no.: " + updatedRow.invoiceNo );
                                reject();
                                return;
                              }  
                              if (oldRow.tableData.checked){
                               // updatedRow.tableData.checked = oldRow.tableData.checked;
                                //selectionProps: oldrow => ({
                                //  checked:true,})
                                //updatedRow.tableData.checked = true;
                              }
                              

                              //update selected amount in invoice array
                              //handleCheckboxClick((rowData?.tableData?.checked),rowData.tobepay, rowData.invoiceNo, rowData.dueAmount);

                              const index=oldRow.tableData.id;
                              const updatedRows=[...data]
                              updatedRows[index]=updatedRow
                              setTimeout(() => {
                                setData(updatedRows)
                                resolve()
                              }, 2000)
                            })
                          }}


/*                           onSelectionChange={(evt,rowData) => {

                            
                            if (rowData.tobepay ===undefined) {
                                rowData.tobepay = rowData.dueAmount;
                            }
                            handleCheckboxClick((rowData?.tableData?.checked),rowData.tobepay, rowData.invoiceNo, rowData.dueAmount);
                          }}  */

/*                           onSelectionChange={(rows, rowData) => {
                            const updatedData = data?.map((t) => {
                                  if (t._id === rowData._id) {
                                     return {
                                         ...t,
                                         tableData: rowData.tableData,
                                      };
                                   } else {
                                        return t;
                                   }
                              });
                              
                              this.setState({
                                  data: updatedData,
                                  selectedRows: rows,
                               });
                        }} */

                          options={{
                            search:false,
                            actionsColumnIndex: 0,
                            paging:false,
                            selection: true,
/*                             selectionProps: rowData => ({
                              checked: rowData.tobepay > 0,
                              onClick: (event , rowData) => this.handleCheckboxClick(event,rowData),
                          }), */
                            showSelectAllCheckbox: false,
                            filtering:false,
                            showTextRowsSelected:false,  
                            headerStyle: {
                                backgroundColor: '#6699cc',
                                color: '#FFF',
                                fontStyle:'bold',
                                position: 'sticky', 
                                top: 0 
                                },
                                
                            selectionProps: rowData => ({
                                disabled: rowData.invStatus === 'NACH Presented',
                                color: 'primary',})
                                
                          }}
                         

                    />
                 </ThemeProvider> 
            </section>
        </main>
{/*       <MaterialTable
        title="Employee Data"
        data={data}
        columns={columns}
        editable={{
          onRowAdd: (newRow) => new Promise((resolve, reject) => {
            const updatedRows = [...data, { id: Math.floor(Math.random() * 100), ...newRow }]
            setTimeout(() => {
              setData(updatedRows)
              resolve()
            }, 2000)
          }),
          onRowDelete: selectedRow => new Promise((resolve, reject) => {
            const index = selectedRow.tableData.id;
            const updatedRows = [...data]
            updatedRows.splice(index, 1)
            setTimeout(() => {
              setData(updatedRows)
              resolve()
            }, 2000)
          }),
          onRowUpdate:(updatedRow,oldRow)=>new Promise((resolve,reject)=>{
            const index=oldRow.tableData.id;
            const updatedRows=[...data]
            updatedRows[index]=updatedRow
            setTimeout(() => {
              setData(updatedRows)
              resolve()
            }, 2000)
          })

        }}
        options={{
          actionsColumnIndex: -1, addRowPosition: "first"
        }}
      />
 */}    </div>
  );
}

