
import React, {useState} from "react";
import axios from 'axios';
import "./input.css";
import { Button } from "@material-ui/core";


const Sanction = () => {
    const [isApproved, setisApproved] = useState('N'); 
    const [sanctionLetter, setsanctionLetter] = useState();
    const [userSelection, setuserSelection] = useState("");
    const [iLanNo, setiLanNo] = useState();
    const [isLoad, setisLoad] = useState(true);
    const [apibaseurl, setapibaseurl]=useState('https://los.statuscapitals.com/apigateway/statusapi/credit/sanction/details/');

    let encoded = (window.location.href.substring(window.location.href.lastIndexOf('/') + 1))

    let mylan = Buffer.from(encoded,'base64').toString();

    if (!Number.isInteger(parseInt(mylan, 10))) {
        if (isLoad) {
            alert("Something went wrong or wrong url, please try after sometime");
            return false;
        }
    } 
    if (isLoad) {
        setisLoad(false);
        setiLanNo(mylan);
        let url = apibaseurl + mylan;
   
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
  
        headers: { 
          'client-id': 'STATU0004200016', 
          'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
          'Accept': 'application/json', 
          'Content-Type': 'application/json'
        }
      };
      axios.request(config)
      .then((response) => {
        //console.log(response.data.data);
        if (JSON.stringify(response.data.responseCode)==200){
          setsanctionLetter(response.data.data.sanctionLetter);
          setuserSelection(response.data.data.customerAcceptanceStatus);
          if (response.data.data.customerAcceptanceStatus === "APPR") {
             setisApproved('Y');
          }  
        }
      })
      .catch((error) => {
      });

    }
    const handleChangeuserSelection = (e) => {
        setuserSelection(e.target.value);
  
    }
    function handleSubmit () {
        var userreason =  document.getElementById('Reason').value;
        if ((userreason === "") && (userSelection === "REJ" || userSelection==="QRY"))  {
           alert("Query details are required");   
           return false;  
        }

        let data = JSON.stringify({
          "query": ""+userreason+""
        });
        var url = apibaseurl + iLanNo+'?status='+userSelection;
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: url,
          headers: { 
            'client-id': 'STATU0004200016', 
            'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
            'Accept': 'application/json', 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));

            if (JSON.stringify(response.data.responseCode)==200){
                alert("Your response submitted successfully, please close this windows");
            }else{
                alert("Facing some issue in getting details, please retry again!!!")
            }
        })
        .catch((error) => {
          console.log(error);
          alert("Facing some issue in getting details, please retry again!!!")
        });
     }

    return(
        <>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <b>Sanction Letter</b>
            </div> 

            <table style={{ 'width':'100%', 'border': '1px solid black','borderCollapse': 'collapse' }}>
                <tbody>
                    <tr>

                        <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }} hidden={isApproved==="N"}>
                            <font color="red"> You have already 'Accecpted' this sanction letter, so you can not change your response now!!!
                            </font>  
                        </td>
                    </tr>
                    <tr>
                        <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>

                            <div className="scrollable-div" dangerouslySetInnerHTML={{ __html: sanctionLetter }}></div>

                        </td>
                    </tr>
                    {/* <tr>
                        <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Your response for above letter? </td>
                    </tr> */}
                    <tr>
                        <td >
                            Your response for above letter? &nbsp;
                            <input type="radio" name="userSelection" id='userSelection-1' value="APPR" onChange={handleChangeuserSelection} checked={userSelection === 'APPR'} />
                            <label htmlFor="userSelection-1">Accept</label>
{/*                             <input type="radio" name="userSelection" id='userSelection-2' value="REJ" disabled={isApproved==="Y"}  onChange={handleChangeuserSelection} checked={userSelection === 'REJ'} />
                            <label htmlFor="userSelection-2">Reject</label> */}
                            <input type="radio" name="userSelection" id='userSelection-3' value="QRY" disabled={isApproved==="Y"}  onChange={handleChangeuserSelection} checked={userSelection === 'QRY'} />
                            <label htmlFor="userSelection-3">Have a query for above letter? </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Query Details:</b>
                        </td>
                    </tr>
                    <tr>
                        <td>                        
                            <textarea 
                                id="Reason"
                                cols="100"
                                rows="4"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Enter your query about above santion letter"
                                disabled={isApproved==="Y"}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td> 
                            <Button
                                style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}
                                disabled={isApproved==="Y"}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Submit your response
                            </Button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </>



    );
}
export default Sanction;