import React from "react";
//import { Link } from "react-router-dom";

// components

//import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ProductsHero from "./ProductsHero";
import ProductsMain from "./ProductsMain";
import ProductsDetails from "./ProductDetails";

export default function Products() {
  return (
    <>
{/*       <Navbar transparent /> */}
      <IndexNavbar fixed />
      <ProductsHero/>
      <ProductsMain/>
      <ProductsDetails/>  
      <Footer />
    </>
  );
}
