const nodemailer = require('nodemailer');

// Set this from config or environment variable.
const PASSWORD = 'Bhandup@8871';

async function send365Email(from, to, subject, html, text) {
    try { 
        const transportOptions = {
            host: 'smtp.office365.com',
            port: '587',
            auth: { user: from, pass: PASSWORD },
            secureConnection: true,
            tls: { ciphers: 'SSLv3' }
        };
    
        const mailTransport = nodemailer.createTransport(transportOptions);
    
        await mailTransport.sendMail({
            from,
            to,
            replyTo: from,
            subject,
            html,
            text
        });
    } catch (err) { 
        console.log(`send365Email: An error occurred:`, err);
    }
}

const mymail = () => {
   return(<div><input type='button' onClick={() => {
    send365Email("vausdeo.patil@yarnbiz.com", "vausdeo.patil@yarnbiz.com", "Subject", "<i>Hello World</i>", "Hello World");
    }} value='Send Mail'></input></div>);
    
}
export default mymail;