import React from "react";
//import { Link } from "react-router-dom";

// components

//import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ContactHero from "./ContactHero";
import ContactMain from "./ContactsMain";

export default function Contact() {
  return (
    <>
{/*       <Navbar transparent /> */}
      <IndexNavbar fixed />
      <ContactHero/>
      <ContactMain/>  
      <Footer />
    </>
  );
}
