import React from "react";
//import { Link } from "react-router-dom";

// components

//import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import PolicyHero from "./PolicyHero";
import PolicyMain from "./PolicyMain";

export default function Policy() {
  return (
    <>
{/*       <Navbar transparent /> */}
      <IndexNavbar fixed />
      <PolicyHero/>
      <PolicyMain/>  
      <Footer />
    </>
  );
}
