/* import React, { Component } from 'react';
import MaterialTable from 'material-table';




function Transactions() {
    return (
      <>  
      <MaterialTable
        title="Overriding Export Function Preview"
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Surname', field: 'surname' },
          { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
          {
            title: 'Birth Place',
            field: 'birthCity',
            lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
          },
        ]}
        data={[
          { name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 },
          { name: 'Zerya Betül', surname: 'Baran', birthYear: 2017, birthCity: 34 },
        ]}        
        options={{
          exportButton: true,
          exportCsv: (columns, data) => {
            alert('You should develop a code to export ' + data.length + ' rows');
          }
        }}
      />
      </>
    )
  }
  export default Transactions; */


  //import { Typography } from "@material-ui/core";
import React from 'react';
//import ReactDOM from 'react-dom';
import MaterialTable from 'material-table';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { forwardRef } from 'react';

//import FooterSmall from "components/Footers/FooterSmall.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
//import FilterList from '@material-ui/icons/FilterList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
//import { main } from '@popperjs/core';
import { checksession } from './checksession'; 

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterAltIcon {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


function Transactions() {
    const tableRef = React.createRef();
    const defaultMaterialTheme = createTheme();
    const { useState } = React;
    const [selectedRow, setSelectedRow] = useState(null);
    const token = "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJwcmFzYWQucGFzZUA0ZmluLmluIiwiZXhwIjoxNjg5MTY1Mzg1LCJpYXQiOjE2ODY1NzMzODV9.2GMbe65q6twhrXp8s4BQHYPGRNknbRgpMSZV7v2dNJA";
    //const [data, setData] = useState([]);

    if (checksession()) {
    }else{
      return false;
    }

  
    return (
       
      /* <MaterialTable        
        title="Refresh Data Preview"
        tableRef={tableRef}
        columns={[
          {
            title: 'Avatar',
            field: 'avatar',
            render: rowData => (
              <img
                style={{ height: 36, borderRadius: '50%' }}
                src={rowData.avatar}
              />
            ),
          },
          { title: 'Id', field: 'id' },
          { title: 'First Name', field: 'first_name' },
          { title: 'Last Name', field: 'last_name' },
        ]}
        data={query =>
          new Promise((resolve, reject) => {
            let url = 'https://reqres.in/api/users?'
            url += 'per_page=' + query.pageSize
            url += '&page=' + (query.page + 1)
            fetch(url)
              .then(response => response.json())
              .then(result => {
                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.total,
                })
              })
          })
        }
        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
          }
        ]}
      /> */
      <main>
      <IndexNavbar fixed />
      <section className="relative py-20">
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          title="Invoices List"
          tableRef={tableRef}
          icons={tableIcons}
          size="small"
          columns1={[
/*              {
              title: 'Invoice No', 
              field: 'invoiceNo',
              //customFilterAndSearch: (term, rowData) => term == rowData.name.length
            },
            { title: 'Surname', field: 'surname' },
            { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
            {
              title: 'Birth Place',
              field: 'birthCity',
              lookup: { 34: 'İstanbul', 63: 'Şanlıurfa', 69:'Mumbai' },
            },  */

             {
              title: 'ID', 
              field: 'id'
            },
            {
              title: 'Node ID', 
              field: 'node_id'
            },
            {
              title: 'Name', 
              field: 'full_name'
            } /*,
            { title: 'Id', field: 'id' },
            { title: 'First Name', field: 'first_name' },
            { title: 'Last Name', field: 'last_name' },*/
          ]}

          columns={[
/*             {
              title: 'Avatar',
              field: 'avatar',
              render: rowData => (
                <img
                  style={{ height: 36, borderRadius: '50%' }}
                  src={rowData.avatar}
                />
              ),
            },
            { title: 'Id', field: 'id' },
            { title: 'First Name', field: 'first_name' },
            { title: 'Last Name', field: 'last_name' }, */
            { title: 'Invoice Date', field:'invoiceDate'},
            { title: 'Invoice Amount', field:'invoiceAmount'},
            { title: 'Credit Days', field:'creditDays'},
            { title: 'Interest Rate', field:'interestRate'},
            { title: 'Due Date', field:'dueDate'},
            { title: 'Due Amount', field:'dueAmount'},
            { title: 'Paid Amount', field:'paidAmount'},
            { title: 'Utilized Limit', field:'utilizedLimit'}
          ]}

          data1={[
            {
              "id": 6104546,
              "node_id": "MDEwOlJlcG9zaXRvcnk2MTA0NTQ2",
              "full_name": "mralexgray/-REPONAME",
          },
          {
              "id": 104510411,
              "node_id": "MDEwOlJlcG9zaXRvcnkxMDQ1MTA0MTE=",
              "full_name": "mralexgray/...",
          },
          {
              "id": 58656723,
              "node_id": "MDEwOlJlcG9zaXRvcnk1ODY1NjcyMw==",
              "full_name": "2200087-Serial-Protocol",
          },
          {
              "id": 13121042,
              "node_id": "MDEwOlJlcG9zaXRvcnkxMzEyMTA0Mg==",
              "full_name": "mralexgray/ace",
          },
          {
              "id": 10791045,
              "node_id": "MDEwOlJlcG9zaXRvcnkxMDc5MTA0NQ==",
              "full_name": "mralexgray/ACEView",
          },
          {
              "id": 13623648,
              "node_id": "MDEwOlJlcG9zaXRvcnkxMzYyMzY0OA==",
              "full_name": "mralexgray/ActiveLog",
          },
          {
              "id": 9716210,
              "node_id": "MDEwOlJlcG9zaXRvcnk5NzE2MjEw",
              "full_name": "mralexgray/adium",
          },
          {
              "id": 12752329,
              "node_id": "MDEwOlJlcG9zaXRvcnkxMjc1MjMyOQ==",
              "full_name": "mralexgray/ADLivelyTableView",
          },
          {
              "id": 5697379,
              "node_id": "MDEwOlJlcG9zaXRvcnk1Njk3Mzc5",
              "full_name": "mralexgray/AFIncrementalStore",
          },
          {
              "id": 6969621,
              "node_id": "MDEwOlJlcG9zaXRvcnk2OTY5NjIx",
              "full_name": "mralexgray/AFNetworking",
          },
          {
              "id": 9485541,
              "node_id": "MDEwOlJlcG9zaXRvcnk5NDg1NTQx",
              "full_name": "mralexgray/AGNSSplitView",
          },
          {
              "id": 12767784,
              "node_id": "MDEwOlJlcG9zaXRvcnkxMjc2Nzc4NA==",
              "full_name": "mralexgray/AGScopeBar",
          },
          {
              "id": 31829499,
              "node_id": "MDEwOlJlcG9zaXRvcnkzMTgyOTQ5OQ==",
              "full_name": "mralexgray/agvtool-xcode-plugin",
          },
          {
              "id": 9227846,
              "node_id": "MDEwOlJlcG9zaXRvcnk5MjI3ODQ2",
              "full_name": "mralexgray/AHContentBrowser",
          },
          {
              "id": 37430328,
              "node_id": "MDEwOlJlcG9zaXRvcnkzNzQzMDMyOA==",
              "full_name": "mralexgray/AHLaunchCtl",
          },
          {
              "id": 9167473,
              "node_id": "MDEwOlJlcG9zaXRvcnk5MTY3NDcz",
              "full_name": "mralexgray/AHLayout",
          },
          {
              "id": 18450201,
              "node_id": "MDEwOlJlcG9zaXRvcnkxODQ1MDIwMQ==",
              "full_name": "mralexgray/Airmail-Plug-In-Framework",
          },
          {
              "id": 5203219,
              "node_id": "MDEwOlJlcG9zaXRvcnk1MjAzMjE5",
              "full_name": "mralexgray/AJS-iTunes-API",
          },
          {
              "id": 10093801,
              "node_id": "MDEwOlJlcG9zaXRvcnkxMDA5MzgwMQ==",
              "full_name": "mralexgray/Alcatraz",
          },
          {
              "id": 12916552,
              "node_id": "MDEwOlJlcG9zaXRvcnkxMjkxNjU1Mg==",
              "full_name": "mralexgray/alcatraz-packages",
          },
          {
              "id": 44278362,
              "node_id": "MDEwOlJlcG9zaXRvcnk0NDI3ODM2Mg==",
              "full_name": "mralexgray/alexicons",
          },
          {
              "id": 10476467,
              "node_id": "MDEwOlJlcG9zaXRvcnkxMDQ3NjQ2Nw==",
              "full_name": "mralexgray/Alfred-Google-Translate",
          }
          ]}

          data={query =>
            new Promise((resolve, reject) => {
              //let url = 'https://reqres.in/api/users?'
              //let url = 'https://reqres.in/api/users?'
              let url = 'https://yarnbizqalms.4fin.in/lms/invoices?limitApplicationNumber=78&'              
              url += 'per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)
            fetch(url, {
            method: "GET",
            headers: {"Authorization": `Bearer ${token}`}
            })
              //fetch(url)
                .then(response => response.json())
                .then(result => {
                  resolve({
                    data: result.data,
                    page: result.page - 1,
                    totalCount: result.total,
                  })
                })
            })
          }

 /*            data={query =>
             new Promise((resolve, reject) => {
               //let url = 'https://yarnbizqa.4fin.in/apigateway/statusapi/yarnbiz/allinvoices?buyerId=19'
               //let url = 'https://api.github.com/users/mralexgray/repos?';
               let url = 'https://api.github.com/users/mralexgray/repos?per_page=5&page=2';
               //url += 'per_page=' + query.pageSize
               //url += '&page=' + (query.page + 1)
               //let url = 'https://reqres.in/api/users?'
               //url += 'per_page=' + query.pageSize
               //url += '&page=' + (query.page + 1)
               //let url ='https://api.github.com/users/mralexgray/repos?per_page=5&page=1'
              //alert(url);
               fetch(url, {
         method: 'GET',
         headers: {
//         'authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJyaXRlc2gucGFuZGV5QDRmaW4uaW4iLCJleHAiOjE2ODYyMjMwNzIsImlhdCI6MTY4MzYzMTA3Mn0.aeE0sBs53-5uGx2w9zNPTx7kAqIx44Cek1phAKlkGL4',
//         Accept: 'application/json',
         },
     }) 
                 .then(response => response.json())
                 .then(result => {
                   resolve({
                     data: result.data,
                     page: result.page - 1,
                     totalCount: result.total,
                     
                   })
                   
                 })
//                 /* .then(error=>{
                    
//                   console.log(error);
//                 }) *

             })
             
           } */
           
/*            actions={[
             {
               icon: 'refresh',
               tooltip: 'Refresh Data',
               isFreeAction: true,
               onClick: () => tableRef.current && tableRef.current.onQueryChange(),
             } 
           ]}  */
/*          {
            title: 'Name', 
            field: 'name',
            //customFilterAndSearch: (term, rowData) => term == rowData.name.length
          },
          { title: 'Surname', field: 'surname' },
          { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
          {
            title: 'Birth Place',
            field: 'birthCity',
            lookup: { 34: 'İstanbul', 63: 'Şanlıurfa', 69:'Mumbai' },
          },
        ]}
      
        data={[
          { name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 },
          { name: 'Zerya Betül', surname: 'Baran', birthYear: 2017, birthCity: 34 },
          { name: 'Dipti V.', surname:'Mhatre', birthYear:1978, birthCity:69 }
        ]}
 

*/
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        localization={{
          toolbar: {
            exportCSVName: "Download Transactions - Excel",
            exportPDFName: "Download Transactions - PDF"
            //exportPDFName: SaveAlt
          }
        }}
          options={{
            filtering: true, 
            filterRowStyle:{
              //backgroundColor: '#01579b',
              height: 5
            },
            exportButton: true,
            exportAllData: true,
            paging:false,
        pageSize:100000,       // make initial page size
        emptyRowsWhenPaging: false,   // To avoid of having empty rows
        //pageSizeOptions:[8,16,24,32],    // rows selection options
        pageSizeOptions:[100000],

        headerStyle: {
            backgroundColor: '#6699cc',
            //backgroundColor: {blueGray},
            color: '#FFF',
            fontStyle:'bold',
            position: 'sticky', 
            top: 0 
            //color:
            },
            maxBodyHeight: 480,
            cellStyle: {
            /* backgroundColor: '#039be5',
            color: '#FFF', */
            "font-size":11
            },
            rowStyle: rowData => ({
            rowStyle: {height: 10},
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
            })
          }} 
      />
    </ThemeProvider> 
    {/* <FooterSmall absolute /> */}
    </section>
    </main>
    )
  }
  
export default Transactions;
