import React, {useState} from "react";

import { Container, makeStyles } from '@material-ui/core';
import createBrowserHistory from 'history/createBrowserHistory';



const useStyles = makeStyles(theme => ({
  root: {
    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
}));


export default function Login() {

  const [mobile, setMobile] = useState("")
  const [password, setPassword] = useState("")
  
  const classes = useStyles();
  //const navigate = useNavigate();
  const history = createBrowserHistory({forceRefresh:true});
  const handleSubmit = (event) => {
      event.preventDefault()
      
      //alert (password);
      //alert (mobile);
      
/*       return <Redirect to='dashboard' /> */
      
      
      //navigate('Dashboard');
/*       const history = useHistory();
      history.push('Dashboard');  */
      //let path = '/admin'; 
      //alert("submitting");
    //history.push(path);
    let mysessiontime = new Date();
    let username = "Vasudeo Patil";
    //localStorage.setItem("user", mobile);
    //localStorage.setItem("sessionstart", mysessiontime);
    sessionStorage.setItem("user", mobile);
    sessionStorage.setItem("sessionstart", mysessiontime);
    sessionStorage.setItem("registeruser", "YES");
    sessionStorage.setItem("username", username);
    //history.push('/admin')
    //alert("submitted");
    history.push('/');
    //store session details
  }


/*   function phoneno(){          
    $('#mobile').keypress(function(e) {
        var a = [];
        var k = e.which;
        let i=0;
        for (i = 48; i < 58; i++)
            a.push(i);

        if (!(a.indexOf(k)>=0))
            e.preventDefault();
    });
} */

  return (
    <>
    
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Sign in with Mobile and Password
                  </h6>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <Container className={classes.root}> 
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Mobile
                      </label>

                      <input
                        type="number"
                        id="mobile"
                        max="9999999999" 
                        min="1000000000"
                        /* onKeyUp={(e)=>{ 
                          e.target.value = checkMobile(e.target.value)
                        }}  */
                      // pattern="[0-9]{1}[0-9]{9}"
                      //onkeypress="phoneno()"
                      //pattern="\d[1]{1}[0-9]{n-1}[0-9]{*}"
                        //pattern="\d{10}"
                        title="Must be 10 digit Mobile number"
                        onChange={e => setMobile(e.target.value)}
                        //oninvalid="setCustomValidity('Must be 10 digit Mobile number')" 
                        //oninput="setCustomValidity('')"
                         onInput={(e)=>{ 
                          e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                        }}  
                        //oninput={e => check(this)}
                        //oninput="myFunction(this.value)"
                        pattern=".{10,}"
                        maxLength={10}
                        minLength={10}
                        required
                        className="classes.root border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 "
                        placeholder="Enter your 10 digit Mobile number"
                      />
                      
  {/*                    <input name="phone" maxlength="10" minLength={10} pattern="\d{10}" class="form-control" id="mobile"  placeholder="Enter your 10 digit mobile number" type="number" title="Please use a 10 digit mobile number with no dashes or dots" required/>
                    <input name="phone" maxlength="10" minLength={10} pattern="[1-9]{1}[0-9]{9}" class="form-control" id="mobile"  placeholder="Enter your 10 digit mobile number" type="number" title="Please use a 10 digit mobile number with no dashes or dots" required/>
                      <input type="tel" name="mobile" id="mobile" maxLength="10" minLength="10" title="Please use a 10 digit mobile number with no dashes or dots" pattern="[0-9]{10}" required /> 
                      <input type="text" name="AUS" pattern="[0-9]{10}" title="You can enter only 10 digits..." />
    */}                 </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Enter your password"
                        onChange={e => setPassword(e.target.value)}
                      required
                      value={password}
                      />
                    </div>

                    <div className="text-center mt-6">
                      <button
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Sign In
                      </button>


                    </div>
                  </Container>
                </form>
              </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
          </div>
        </div>
      </div>
    </>
  );
}
