
import React, {useState} from "react";
import axios from 'axios';
import "./input.css";
import { Button } from "@material-ui/core";


const FileUpload = () => {
    const [isApproved, setisApproved] = useState('N'); 
    const [iLanNo, setiLanNo] = useState();
    const [isLoad, setisLoad] = useState(false);
   
      
    const [apibaseurl, setapibaseurl]=useState('https://los.statuscapitals.com/apigateway/statusapi/yarnbiz/processingfees/');

    let encoded = (window.location.href.substring(window.location.href.lastIndexOf('/') + 1))

    let mylan = Buffer.from(encoded,'base64').toString();
    if (!Number.isInteger(parseInt(mylan, 10))) {
        if (isLoad) {
            alert("Something went wrong or wrong url, please try after sometime");
            return false;
        }
    } 

    function handleFileChange(){

    }    
    function handleSubmit () {
        let data = JSON.stringify({
/*             "utrNumber": utrNumber,
            "paymentStatus": paymentStatus,
            "amount": amount */
          });
          
        var url = apibaseurl + iLanNo
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: url,
          headers: { 
            'client-id': 'STATU0004200016', 
            'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
            'Accept': 'application/json', 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));

            if (JSON.stringify(response.data.responseCode)===200){
                alert("Your response submitted successfully, please close this windows");
                return null;
            
            }else{
                alert("Facing some issue in getting details, please retry again!!!")
                return false;
            }
        })
        .catch((error) => {
          alert("Facing some issue in getting details, please retry again!!!")
          return false;
        });
    }

    return(
        <>
            <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            }}>
                <b>File upload</b>
            </div> 

            <table style={{ 'width':'30%',  'margin-left': 'auto', 'margin-right': 'auto','alignItems': 'center', 'justifyContent': 'center', 'border': '1px solid black','borderCollapse': 'collapse' }}>
                        <tbody>
                            <tr>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Select file to upload </td>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','text-align': 'right' }}>
                                <input type='file' id='filetoupload'  onChange={handleFileChange} /> </td>
                            </tr>
                            <tr>
                                <td colspan='2' style={{ 'text-align': 'center'}}> 
                                    <Button
                                        style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}
                                        disabled={isApproved==="Y"}
                                        onClick={() => {
                                           // handleSubmit();
                                        }}
                                    >
                                        Upload File
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

        </>



    );
}
export default FileUpload;