import React from "react";
//import { Link } from "react-router-dom";

// components

//import Navbar from "components/Navbars/AuthNavbar.js";
//import Footer from "components/Footers/Footer.js";

export default function TermsMain() {
  return (
    <>
{/*       <Navbar transparent /> */}
      <main>
{/*         <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
           <div
            className="absolute top-0 w-full h-full bg-center bg-cover bg-red-800"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')",
            }}
          > 
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span> 
          </div>*/}
{/*           <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-5xl">
                    Your story starts with us.
                  </h1>
                  <p className="mt-4 text-lg text-blueGray-200">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nec massa at risus sodales vulputate a at neque. Sed nunc magna, vehicula in magna vitae, feugiat vestibulum mauris. Vestibulum vel tincidunt libero. Donec luctus euismod mi, sit amet auctor nunc aliquet eu. Vivamus vitae dapibus nibh. Suspendisse quis orci urna. Aliquam accumsan ipsum vitae elementum vulputate. Vestibulum faucibus odio ac turpis tristique mollis. In sit amet mauris eu felis bibendum cursus a a diam.
                  </p>
                </div>
              </div>
            </div>
         </div> */} 
{/*           <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div> 
        </div>*/}

 {/*        <section className="pb-20 bg-blueGray-200 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Product/Service 1</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                    Product/Service 1Product/Service 1Product/Service 1Product/Service 1Product/Service 1Product/Service 1.
                    </p>
                  </div>
                </div>
              </div> */}

{/*               <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Credit for Yarn purchase</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                    Status Capitals offers a credit to purchase yarn. 
                    </p>
                  </div>
                </div>
              </div> */}

{/*               <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Product/Service 2</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                    Product/Service 2Product/Service 2Product/Service 2Product/Service 2Product/Service 2Product/Service 2.
                    </p>
                  </div>
                </div>
              </div> 
            </div>*/}

{/*             <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Working with us is a pleasure
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600" >
                  D&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;t.
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                  The kit comes with three pre-built pages to help you get
                  started faster. You can change the text and images and you're
                  good to go. Just make sure you enable them first via
                  JavaScript.
                </p> 
                  <Link to="/" className="font-bold text-blueGray-700 mt-8">
                  Check Notus React!
                </Link> 
              </div>*/}

{/*               <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block h-95-px -top-94-px"
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-lightBlue-500 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                      Top Notch Services
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
                      The Arctic Ocean freezes every winter and much of the
                      sea-ice then thaws every summer, and that process will
                      continue whatever happens.
                    </p>
                  </blockquote>
                </div>
              </div> 
            </div>
          </div>
        </section>*/}

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-bottom flex flex-wrap">
              {/* <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                />
              </div> */}
              <div className="w-full md:w-12/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  {/* <div className="text-lightBlue-700 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-lightBlue-400">
                    <i className="fas fa-rocket text-xl"></i>
                  </div> */}
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                  <b>Modifications / Amendments</b><br></br>
We may, at any time, for any reason, without assigning such reason, without notice, make changes to the website, including its look, feel, format, and content (defined hereafter); the services provided through the website; and these Terms of Use. Any such modifications/amendments as provided for in the clause above will take effect from the time they are posted on the website. By your continued usage of the website upon such changes being posted, you will be deemed to have accepted such changes.
<br></br>
<br></br>
<b>Scope of use</b><br></br>

You may only use the website solely to obtain services provided by the company using the website and for no other purpose whatsoever. You shall not interrupt or attempt to interrupt the website's operation in any way. You shall not intrude or attempt to intrude into the servers hosting the website in any way.
<br></br>
<br></br>

You shall make payments of all amounts to the company when due by these Terms of Use, TOS, and/or Additional Agreements, including any duties, taxes, fees, and/or related charges whether or not included in the amounts quoted on the website. User shall also agree to pay all duties, taxes, and related charges arising out of its use of the services.

<br></br>
<br></br>
If any transaction or attempted transaction relating to Status Capitals Pvt Ltd that violates these Terms of Use, the Privacy Policy (defined hereafter) or applicable law comes to your knowledge, you shall take all steps to inform Status Capitals Pvt Ltd of the same promptly.
<br></br>
<br></br>
You shall at all times ensure full compliance with the applicable laws and rules and regulations regarding your use of our Services.
<br></br>
<br></br>
You are prohibited from collecting email addresses or other contact information of other users, through any means whatsoever, without authorization from Status Capitals Pvt Ltd; selling, cross-selling or distributing Services to any third party or allowing multi-user access to the Services by sharing your password and user identification; using any automated software, hardware or any other similar mechanism to use access, navigate or search the website; and posting or transmitting any information on the website including unauthorized or unsolicited advertising, promotional materials, or any other forms of unauthorized solicitation to other users (other than the information specifically permitted/ required to be posted or transmitted under these Terms of Use, TOS and/or Additional Agreements)
<br></br>
<br></br>
<b>Intellectual property rights</b><br></br>

The website and each of its modules is the copyrighted property of Status Capitals Pvt Ltd and its various third-party providers and distributors. The website contains material, which is protected by copyright and/or other intellectual property rights. Status Capitals Pvt Ltd reserves all rights in the website and its content not specifically granted in TOS, additional agreements and/or these Terms of Use.
<br></br>
<br></br>
Any software that is made available to download from the website is the copyrighted work of Status Capitals Pvt Ltd and/or its various third party providers and distributors. Your use of the software is governed by the terms of the end user license agreement, if any, which accompanies or is included with the software (License Agreement). You may not install or use any software that is accompanied by or includes a License Agreement unless you first agree to the License Agreement terms. For any software not accompanied by a License Agreement, Status Capitals Pvt Ltd hereby grants to you, the User, a personal, non-exclusive, non-transferable license to use the Software for viewing and otherwise using the website in accordance with these Terms of Use and for no other purpose and such license may be terminated and revoked by Status Capitals Pvt Ltd at any time for any reason. Please note that all software is owned by Status Capitals Pvt Ltd and is protected by copyright laws and international treaty provisions. Any reproduction or redistribution of the software is expressly prohibited by law, and may result in severe civil and criminal penalties.
<br></br>
<br></br>
“Status Capitals Pvt Ltd ”, “statuscapitals.com”, “Order From. Order For.” and other Status Capitals Pvt Ltd graphics, logos, taglines and service names are the intellectual property of Status Capitals Pvt Ltd. Except as specifically permitted herein, these shall not be used by the user or any other person without the prior written permission from Status Capitals Pvt Ltd. All other trademarks not owned by Status Capitals Pvt Ltd that appear on the website are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Status Capitals Pvt Ltd.
<br></br>
<br></br>
You may use and display any portion of the content available on the website (including without limitation text, graphics, software, audio and video files and photos) (Content) only on your personal computer, mobile phone, tablet or other similar personal device and only for personal use. You may print copies of the content and store the content on your personal computer, mobile phone, tablet or other similar personal device for your personal use. Status Capitals Pvt Ltd grants you a limited, personal, non-exclusive and non-transferable license only for such use.
<br></br>
<br></br>
You shall not alter or modify the content in any way. You shall not use, reproduce, republish, distribute, prepare derivative works, or otherwise use the content (or any portion thereof) other than as explicitly permitted herein.
<br></br>
<br></br>
You shall not remove any: (a) copyright, trade-mark or other intellectual property notices from any content (or from copies and/or printouts thereof); and/or (b) link to the website from any place, without our express written consent.
<br></br>
<br></br>
You do not acquire any ownership rights to any content. Any unauthorized use of the content shall terminate the permission or license granted by Status Capitals Pvt Ltd herein.
<br></br>
<br></br>
You shall not transfer the content to any other person except with the prior written consent of the company. You agree to abide by all additional restrictions about the content displayed on the website, as may be updated from time to time.
<br></br>
<br></br>
Notwithstanding the above, Status Capitals Pvt Ltd does not represent or guarantee that it has any right (including intellectual property rights) on the content including the right to allow use thereof by the users.
<br></br>
<br></br>
<b>Communication Services and Your Information
</b><br></br>

Please note that in accordance with the Information Technology Act, 2000 and its amendment in 2008 and 2009, and its applicable rules thereunder, in case of non-compliance with applicable law, these Terms of Use and/or the Privacy Policy in relation to the access or usage of our website, we have the right to immediately terminate the access or usage rights of any user of our website and remove any non-compliant information. Without limiting the generality and effect of the foregoing, you undertake that you shall not host, display, upload, modify, publish, transmit or share any information on the website that: belongs to another person and to which you do not have any rights; is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating to or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; harm minors in any way; infringes any patent, trademark, copyright, or other proprietary rights; violates any law for the time being in force; deceives or misleads the addressee about the origin of such messages or communicates an information which is grossly offensive or menacing in nature; impersonate another person; contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; In order to enable Status Capitals Pvt Ltd to use Your Information, so that we are not violating any rights you may have in Your Information, you agree to grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to use Your Information, in any media now known or that may come into existence in future.
<br></br>
<br></br>
Without prejudice to our rights under Clause 7.5 above, to the extent required or permitted by applicable law, we may also collect, use and disclose your information in connection with security-related or law enforcement investigations or in the course of cooperating with authorities or complying with legal requirements.
<br></br>
<br></br>
You shall solely be responsible for any loss that may be incurred by any person as a consequence of the use of/ reliance on Your Information and you agree and undertake to indemnify and hold harmless, the company, its employees, officers, affiliates, partners and group companies from all such losses.
<br></br>
<br></br>
“Loss” for the purposes of these Terms of Use means each loss, damage, fine, penalty, cost, expense or other liability (including legal and other professional fees) and Losses shall be interpreted accordingly.
<br></br>
<br></br>
<b>Links to Third Party Websites

</b><br></br>

The website may be linked to other websites on the World Wide Web that are not under the control of or maintained by Status Capitals Pvt Ltd. Such links do not indicate any responsibility or endorsement on our part for the external website concerned, its contents or the links displayed on it. Status Capitals Pvt Ltd has no responsibility or liability for and makes no representations whatsoever about any other website that you may have access to through our Website. These linked sites are only for your convenience, and therefore, you access them at your own risk.
<br></br>
<br></br>

Status Capitals Pvt Ltd shall not be liable for any loss associated with your use of such other websites. Status Capitals Pvt Ltd seeks to protect the integrity of its Website and the links placed upon it and therefore requests any feedback on not only its own Website and mobile application but also third party links. Status Capitals Pvt Ltd reserves the right, at its sole discretion, to terminate any link or linking program at any time and for any reason it deems appropriate.
<br></br>
<br></br>
<b>Exclusions

</b><br></br>

Your use of the Website is at your own risk. Everything on the Website including the access to listing of and information are provided to you on an "as is where is" basis and without warranty of any kind, either expressed or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. The entire risk as to the results and performance obtained from using the Content is assumed by you.
<br></br>
<br></br>

The Website and the Content contained therein could include technical or other inaccuracies or typographical errors. You agree and acknowledge that much of the Content on the Website is provided and/or uploaded by third parties. Status Capitals Pvt Ltd makes no representations or warranties as to the completeness or accuracy of Content. Status Capitals Pvt Ltd makes no commitment to update or correct the Content that appears on the Website.
<br></br>
<br></br>

Status Capitals Pvt Ltd makes no representations about the suitability of the information, software, products and services contained on the Website for any purpose, and the inclusion or offering for sale of any products or services on the Website does not constitute any endorsement or recommendation of such products or services by Status Capitals Pvt Ltd. All such information, software, products, and services are provided on an "as is where is" basis without warranty of any kind. Status Capitals Pvt Ltd disclaims all warranties, conditions and liability with regard to this information (including, without limitation, photographs, a list of amenities and descriptions of Suppliers), software, products, and services including all implied warranties and conditions of merchantability, fitness for a particular purpose, title, and non-infringement.
<br></br>
<br></br>

Information is supplied upon the condition that the persons receiving the same will make their own determination as to its suitability for their purposes prior to use or in connection with the making of any decision.
<br></br>
<br></br>

Without prejudice to the generality of the above, Status Capitals Pvt Ltd does not confirm or guarantee any accuracy of the ratings provided to the Suppliers listed on the Website and the Mobile application and the same are provided solely for the convenience of the Users.
<br></br>
<br></br>
<b>Limitation of Liability
</b><br></br>


Liability of the Company under these Terms of Use and/or otherwise in relation to the Users’ subscription to or use of any of our Services shall be limited and not exceed the payments specifically required to be made by the Company under these Terms of Use, TOS and/or any Additional Agreements. The Company disclaims all warranties, express or implied, with respect to the obligations performed under these Terms of Use, TOS and/or any Additional Agreements.
<br></br>
<br></br>
<b>Indemnity
</b><br></br>


You agree to indemnify, defend and hold harmless Status Capitals Pvt Ltd, its employees, attorneys and agents, from and against any and all Losses asserted against or incurred by Status Capitals Pvt Ltd that arise out of, result from, or may be payable because of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by you pursuant to these Terms of Use, TOS and Additional Agreements or any other liabilities arising out of your subscription to or use of the Website.
<br></br>
<br></br>

<b>Term and Termination

</b><br></br>

Subject to the provisions of this Clause, these Terms of Use shall remain in full force and effect while you use any Service in any form or capacity.
<br></br>
<br></br>

Status Capitals Pvt Ltd may suspend or terminate your use of the Website at any time in its sole and absolute discretion without serving any notice. Without prejudice to Status Capitals Pvt Ltd’ rights under these Terms of Use to suspend or terminate your use of the Website without any reason or for other reasons specified elsewhere in these Terms of Use, Status Capitals Pvt Ltd may suspend or terminate your use of the Website without any notice if it believes, in its sole and absolute discretion that:
<br></br>
<br></br>

you have breached, violated, abused, or unethically manipulated or exploited any provisions of these Terms of Use, TOS and/or Additional Agreements or have otherwise acted in an unlawful or unethical manner;
the information/details provided by you cannot be verified or authenticated;
your actions may infringe on any third party rights or otherwise result in any liability for Status Capitals Pvt Ltd and/or other Users.
Status Capitals Pvt Ltd reserves the right to recover any amounts due and owing by the User to Status Capitals Pvt Ltd and/or any Supplier and take any legal action for recovery thereof as it deems necessary.
<br></br>
<br></br>

Upon suspension or termination of your use of the Website, you shall have no right and we shall have no obligation thereafter to execute any of your uncompleted tasks or forward any unread or unsent messages to you or any third party. Once the User's registration or the Services are suspended or terminated, any data that you may have stored on the Website may not be retrieved later. In such an event, Status Capitals Pvt Ltd shall not be responsible or liable for any loss or damage that may be caused to you as a consequence of such suspension or termination.
<br></br>
<br></br>

Once the User has been indefinitely suspended or terminated, the User may not register on the Website or use the Website in any manner whatsoever unless specifically permitted Status Capitals Pvt Ltd.
<br></br>
<br></br>

<b>Introduction of new technologies
</b><br></br>

The Company will pay special attention to any money laundering threats that may arise from new or developing technologies including internet transactions that might favour anonymity, and take measures, if needed, to prevent their use in money laundering schemes. Many Companies are engaged in the business of issuing a variety of Electronic Cards that are used by customers for buying goods and services, drawing cash from ATMs, and can be used for electronic transfer of funds. Further, marketing of these cards is generally done through the services of agents. The Company ensures that appropriate KYC procedures are duly applied before issuing the cards, if any, in future to the customers. It is also desirable that agents are also subjected to KYC measures.
<br></br>
<br></br>

<b>Applicability to branches & subsidiaries outside India
</b><br></br>

The above guidelines shall also apply to the branches and majority owned subsidiaries located abroad, especially, in countries which do not or insufficiently apply the FATF Recommendations, to the extent local laws permit. When local applicable laws and regulations prohibit implementation of these guidelines, the same should be brought to the notice of Reserve Bank.

<br></br>
<br></br>

<b>Appointment of principal officer
</b><br></br>


The Company has a senior management officer to be designated as Principal Officer. Principal Officer shall be located at the head/corporate office of the Company and shall be responsible for monitoring and reporting of all transactions and sharing of information as required under the law. He will maintain close liaison with enforcement agencies, banks and any other institution which are involved in the fight against money laundering and combating financing of terrorism.                  </p>

                  {/* <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-fingerprint"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Carefully crafted components
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Amazing products
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="far fa-paper-plane"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Dynamic services
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </section>


{/*         <section className="pb-20 relative block bg-blueGray-800">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-800 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Build something
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                  Put the potentially record low maximum sea ice extent tihs
                  year down to low ice. According to the National Oceanic and
                  Atmospheric Administration, Ted, Scambos.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-medal text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Excelent Services
                </h6>
                <p className="mt-2 mb-4 text-blueGray-400">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-poll text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Grow your market
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-lightbulb text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Launch time
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
        </section> 
        <section className="relative block py-24 lg:pt-0 bg-blueGray-800">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                  <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl font-semibold">
                      Want to work with us?
                    </h4>
                    <p className="leading-relaxed mt-1 mb-4 text-blueGray-500">
                      Complete this form and we will get back to you in 24
                      hours.
                    </p>
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Full Name"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        Message
                      </label>
                      <textarea
                        rows="4"
                        cols="80"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Type a message..."
                      />
                    </div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>*/}
      </main>
{/*       <Footer /> */}
    </>
  );
}
