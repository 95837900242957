
import React, {useState} from "react";
import axios from 'axios';
import "./input.css";
import { Button } from "@material-ui/core";


const Processing = () => {
    const [isApproved, setisApproved] = useState('N'); 
    const [iLanNo, setiLanNo] = useState();
    const [isLoad, setisLoad] = useState(true);
    const [processingStatus, setprocessingStatus] = useState('');
    const [processingAmt, setprocessingAmt] = useState('200');
    const [processingGST, setprocessingGST] = useState('5');
    const [processingFinalAmt, setprocessingFinalAmt] = useState('205');
    const [utrNumber, setutrNumber]=useState('YB001');
    const [paymentStatus, setpaymentStatus]=useState('SUCCESSFUL');
    const [amount, setamount]=useState(0);
    const [apiactivity, setapiactivity]=useState('https://los.statuscapitals.com/apigateway/statusapi/activitydashboard/activitydetails?buyerId=');
    
      
    const [apibaseurl, setapibaseurl]=useState('https://los.statuscapitals.com/apigateway/statusapi/yarnbiz/processingfees/');

    let encoded = (window.location.href.substring(window.location.href.lastIndexOf('/') + 1))

    let mylan = Buffer.from(encoded,'base64').toString();
    if (!Number.isInteger(parseInt(mylan, 10))) {
        if (isLoad) {
            alert("Something went wrong or wrong url, please try after sometime");
            return false;
        }
    } 
    if (isLoad) {
        setisLoad(false);
        setiLanNo(mylan);
        let url = apibaseurl + mylan;
    
        console.log(url);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
  
        headers: { 
          'client-id': 'STATU0004200016', 
          'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
          'Accept': 'application/json', 
          'Content-Type': 'application/json'
        }
      };
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data.responseCode));
        if (JSON.stringify(response.data.responseCode)===200){
          setprocessingStatus(response.data.data.status);
          console.log("A");
          console.log(response.data.data.status);
          console.log("B");  
          setprocessingAmt(response.data.data.amount);
          setprocessingGST(response.data.data.gst);
          setprocessingFinalAmt(response.data.data.finalamt);
          if (response.data.data.status === "SUCCESSFUL") {
             setisApproved('Y');
          }  
        }
      })
      .catch((error) => {
      });
    }

    function handleSubmit () {
        let data = JSON.stringify({
            "utrNumber": utrNumber,
            "paymentStatus": paymentStatus,
            "amount": amount
          });
          
        var url = apibaseurl + iLanNo
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: url,
          headers: { 
            'client-id': 'STATU0004200016', 
            'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
            'Accept': 'application/json', 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data.responseCode));

            if (JSON.stringify(response.data.responseCode)===200){
                alert("Your response submitted successfully, please close this windows");
                return null;
            
            }else{
                alert("Facing some issue in getting details, please retry again!!!")
                return false;
            }
        })
        .catch((error) => {
          alert("Facing some issue in getting details, please retry again!!!")
          return false;
        });
    }

    return(
        <>
            <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            }}>
                <b>Processing Fees Payment</b>
            </div> 

            <table style={{ 'width':'20%',  'margin-left': 'auto', 'margin-right': 'auto','alignItems': 'center', 'justifyContent': 'center', 'border': '1px solid black','borderCollapse': 'collapse' }}>
                        <tbody>
                            <tr>
                                <td colspan = '2' style={{ 'border': '1px solid black','borderCollapse': 'collapse' }} hidden={processingStatus!=="SUCCESSFUL"}>
                                    <font color="red"> You have already paid the processing fee!!!
                                    </font>  
                                </td>
                            </tr>
                            <tr>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}></td>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','text-align': 'right' }}>Amount in (₹)</td>
                            </tr>
                            <tr>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Processing Fee </td>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','text-align': 'right' }}>{processingAmt} </td>
                            </tr>
                            <tr>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>GST </td>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','text-align': 'right' }}>{processingGST} </td>
                            </tr>
                            <tr>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Total </td>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','text-align': 'right' }}>{processingFinalAmt} </td>
                            </tr>
                            <tr>
                                <td colspan='2' style={{ 'text-align': 'center'}}> 
                                    <Button
                                        style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}
                                        disabled={isApproved==="Y"}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    >
                                        Pay Now
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

        </>



    );
}
export default Processing;