import React from "react";
//import { Link } from "react-router-dom";

// components

//import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import RefundHero from "./RefundHero";
import RefundMain from "./RefundMain";

export default function Refund() {
  return (
    <>
{/*       <Navbar transparent /> */}
      <IndexNavbar fixed />
      <RefundHero/>
      <RefundMain/>
      <Footer />
    </>
  );
}
